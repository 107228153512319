import http from '../http'

async function countBy(criteria = {}) {
  const params = {
    ...criteria,
    itemsPerPage: 0,
  }
  return (await http.get('/api/course_students', {params})).data['hydra:totalItems']
}

export default {
  countBy,
}
