import { onUnauthorized, getErrorReason } from './http-error'
import auth from './resources/auth'
import school from './resources/school'
import absenceReasons from './resources/absence-reasons'
import academicYears from './resources/academic-years'
import actualLessons from './resources/actual-lessons'
import classrooms from './resources/classrooms'
import courses from './resources/courses'
import courseStudents from './resources/course-students'
import dayPlans from './resources/day-plans'
import schoolClasses from './resources/school-classes'
import schoolClassStudents from './resources/school-class-students'
import schoolSubjects from './resources/school-subjects'
import sections from './resources/sections'
import studentAbsences from './resources/student-absences'
import students from './resources/students'
import teachers from './resources/teachers'
import users from './resources/users'
import weekComments from './resources/week-comments'
import weekReports from './resources/week-reports'

export default {
  ...auth,
  ...school,
  onUnauthorized,
  getErrorReason,
  absenceReasons,
  academicYears,
  actualLessons,
  classrooms,
  courses,
  courseStudents,
  dayPlans,
  schoolClasses,
  schoolClassStudents,
  schoolSubjects,
  sections,
  studentAbsences,
  students,
  teachers,
  users,
  weekComments,
  weekReports,
}
