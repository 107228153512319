import http from '../http'
import ActualLesson from '@/entities/actual-lesson'

async function find(id, options = {}) {
  const params = {
    groups: makeGroups(options)
  }
  const { data } = await http.get(`/api/actual_lessons/${id}`, {params})
  return new ActualLesson(data)
}

async function findByContextObject(contextObject, criteria = {}, options = {}) {
  const iri = contextObject?.['@id']
  const type = contextObject?.['@type']
  if (!(iri && ['SchoolClass', 'Course', 'Teacher', 'Classroom'].includes(type))) return

  const params = {
    ...criteria,
    groups: makeGroups(options),
  }
  return (await http.get(`${iri}/actual_lessons`, {params}))
    .data
    .map((raw) => new ActualLesson(raw))
}

function findBySchoolClass(schoolClass, criteria = {}, options = {}) {
  const contextObject = {
    '@id': schoolClass?.['@id'] || schoolClass,
    '@type': 'SchoolClass',
  }
  return findByContextObject(contextObject, criteria, options)
}

async function update(id, patch) {
  const { data } = await http.put(`/api/actual_lessons/${id}`, patch)
  return new ActualLesson(data)
}

async function updateIsCanceled(id, isCanceled = true) {
  await http.put(`/api/actual_lessons/${id}/is_canceled`, {isCanceled})
}

async function updateReplacement(id, replacement) {
  await http.put(`/api/actual_lessons/${id}/replacement`, replacement)
}

async function removeReplacement(id) {
  await http.delete(`/api/actual_lessons/${id}/replacement`)
}

function makeGroups(options) {
  const groupsMap = {
    brief: 'actual_lesson:brief',
    full: 'actual_lesson:full',
    includeScheduledLesson: 'actual_lesson:include_scheduled_lesson',
  }

  const groups = []
  for (const opt in groupsMap) {
    if (options[opt]) {
      groups.push(groupsMap[opt])
    }
  }

  return groups
}

export default {
  find,
  findByContextObject,
  findBySchoolClass,
  update,
  updateIsCanceled,
  updateReplacement,
  removeReplacement,
}
