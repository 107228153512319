import apiClient from '@/api-client'
import { dateFromString } from '@/helpers/datetime'


const state = {
  pending: [],
  academicYears: [],
  absenceReasons: [],
  absenceReasonsMap: new Map(),
  classrooms: [],
  classroomsMap: new Map(),
  teachers: [],
  teachersMap: new Map(),
  schoolSubjects: [],
  schoolSubjectsMap: new Map(),
  schoolClasses: [],
  schoolClassesMap: new Map(),
  courses: [],
  coursesMap: new Map(),
  sections: [],
  sectionsMap: new Map(),
  dayPlans: [],
  dayPlansMap: new Map(),
  dayPlanSlotsMap: new Map()
}


const getters = {

  loading(state) {
    return state.pending.length > 0
  },

  absenceReasonByIri(state) {
    return iri => state.absenceReasonsMap.get(iri)
  },

  classroomByIri(state) {
    return iri => state.classroomsMap.get(iri)
  },

  teacherByIri(state) {
    return iri => state.teachersMap.get(iri)
  },

  schoolSubjectByIri(state) {
    return iri => state.schoolSubjectsMap.get(iri)
  },

  schoolClassByIri(state) {
    return iri => state.schoolClassesMap.get(iri)
  },

  courseByIri(state) {
    return iri => state.coursesMap.get(iri)
  },

  sectionByIri(state) {
    return iri => state.sectionsMap.get(iri)
  },

  dayPlanByIri(state) {
    return iri => state.dayPlansMap.get(iri)
  },

  dayPlanSlotByIri(state) {
    return iri => state.dayPlanSlotsMap.get(iri)
  },

  sectionByDate(state) {
    return dateString => state.sections.find(s => s.isRunning(dateString)) || null
  },

  sectionsByDates(state, getters) {
    return dates => {
      const { sectionByDate } = getters
      const findSection = dateString => sectionByDate(dateString)
      const unique = array => [...new Set(array)]
      const notEmpty = item => item
      return unique(dates.map(findSection)).filter(notEmpty)
    }
  },

  dayPlanByDate(state, getters) {
    return dateString => {
      const section = getters.sectionByDate(dateString)
      return section && state.dayPlans.findLast(({ startsOn, endsOn }) =>
        (!startsOn || startsOn <= dateString) &&
        (!endsOn || dateString <= endsOn)
      ) || null
    }
  },

  dayPlanSlotsByDate(state, getters) {
    return dateString => {
      const dayOfWeek = dateFromString(dateString).getDay()
      const byDayOfWeek = item => item.dayOfWeek === dayOfWeek
      const byStartTime = ({ startsAt: a }, { startsAt: b }) => (a < b ? -1 : (a > b ? 1 : 0))
      const dayPlan = getters.dayPlanByDate(dateString)
      return (dayPlan?.dayPlanSlots || []).filter(byDayOfWeek).sort(byStartTime) || []
    }
  },

  studentSchoolClassByAcademicYear(state, getters) {
    return (student, academicYear) => {
      const academicYearIri = academicYear?.['@id']
      for (const { schoolClass: schoolClassIri } of student.schoolClassStudents) {
        const schoolClass = getters.schoolClassByIri(schoolClassIri)
        if (schoolClass?.academicYear === academicYearIri) return schoolClass
      }
      return null
    }
  }

}


const mutations = {

  ADD_PENDING(state, token) {
    if (state.pending.includes(token)) return
    state.pending = [...state.pending, token]
  },

  REMOVE_PENDING(state, token) {
    if (!state.pending.includes(token)) return
    state.pending = state.pending.filter(item => item !== token)
  },

  SET_ITEMS(state, { resourceName, items }) {
    const mapName = `${resourceName}Map`
    state[resourceName] = items
    if (mapName in state) {
      state[mapName] = new Map(items.map(item => [item['@id'], item]))
    }
  },

  SET_DAYPLANS(state, dayPlans) {
    state.dayPlans = dayPlans
    state.dayPlansMap = new Map(dayPlans.map(dayPlan => [dayPlan['@id'], dayPlan]))
    state.dayPlanSlotsMap = new Map(
      [].concat(
        ...dayPlans.map(dayPlan => dayPlan.dayPlanSlots)
      ).map(dayPlanSlot => [dayPlanSlot['@id'], dayPlanSlot])
    )
  }

}


const actions = {

  async fetchCollection({ state, commit }, resourceName) {
    if (state.pending.includes(resourceName)) return

    if (!apiClient[resourceName]?.findAll) {
      throw new Error('Unknown resource: ' + resourceName)
    }

    commit('ADD_PENDING', resourceName)
    try {
      let items = await apiClient[resourceName].findAll()
      commit('SET_ITEMS', {resourceName, items})
    } catch (e) {
      console.error(e)
    } finally {
      commit('REMOVE_PENDING', resourceName)
    }
  },

  async fetchCollectionOnce({ state, dispatch }, resourceName) {
    if ('string' == typeof resourceName) {
      if (!apiClient[resourceName]?.findAll) {
        throw new Error('Unknown resource: ' + resourceName)
      }
      if (state[resourceName].length === 0) {
        await dispatch('fetchCollection', resourceName)
      }
    } else if (Array.isArray(resourceName)) {
      await Promise.all(
        resourceName.map((name) => dispatch('fetchCollectionOnce', name))
      )
    }
  },

  async fetchSchoolClassesByAcademicYear({ commit, state }, academicYear) {
    if (!academicYear) return
    const token = `${academicYear['@id']}/school_classes`
    if (state.schoolClasses.token === token || state.pending.includes(token)) return
    commit('ADD_PENDING', token)
    try {
      const schoolClasses = await apiClient.schoolClasses.findByAcademicYear(academicYear)
      schoolClasses.token = token
      commit('SET_ITEMS', {resourceName: 'schoolClasses', items: schoolClasses})
    } catch (e) {
      console.error(e)
    } finally {
      commit('REMOVE_PENDING', token)
    }
  },

  async fetchCoursesBySection({ state, commit }, section) {
    if (!section) return
    const token = `${section['@id']}/courses`
    if (state.courses.token === token || state.pending.includes(token)) return
    commit('ADD_PENDING', token)
    try {
      const courses = await apiClient.courses.findBySection(section)
      courses.token = token
      commit('SET_ITEMS', {resourceName: 'courses', items: courses})
    } catch (e) {
      console.error(e)
    } finally {
      commit('REMOVE_PENDING', token)
    }
  },

  async fetchDayPlansBySection({ state, commit }, section) {
    if (!section) return
    const token = `${section['@id']}/day_plans`
    if (state.dayPlans.token === token || state.pending.includes(token)) return
    commit('ADD_PENDING', token)
    try {
      const dayPlans = await apiClient.dayPlans.findBySection(section)
      dayPlans.token = token
      commit('SET_DAYPLANS', dayPlans)
    } catch (e) {
      console.error(e)
    } finally {
      commit('REMOVE_PENDING', token)
    }
  }

}


export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
