import http from '../http'
import Student from '@/entities/student'

async function findByActualLesson(actualLesson) {
  const actualLessonIri = actualLesson?.['@id'] || actualLesson
  if (!actualLessonIri) return
  return (await http.get(`${actualLessonIri}/students`))
    .data['hydra:member']
    .map((raw) => new Student(raw))
}

async function search(term, limit = 10) {
  const params = {
    pagination: true,
    itemsPerPage: limit,
    search: term,
    'order[lastName]': 'asc',
    'order[firstName]': 'asc',
    groups: ['actual_lesson:student'],
  }

  return (await http.get('/api/students', {params}))
    .data['hydra:member']
    .map((raw) => new Student(raw))
}

export default {
  findByActualLesson,
  search,
}
