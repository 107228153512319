import http from '../http'

async function findAll() {
  return (await http.get('/api/school_subjects', {
    params: {
      pagination: false,
      'order[name]': 'asc'
    }
  })).data['hydra:member']
}

export default {
  findAll,
}
