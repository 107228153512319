import http from './http'

const knownErrorProps = [
  'details', 'hydra:description', 'description', 'error', 'message'
]

const knownErrorMessages = {
  'Invalid credentials.': 'Ungültige E-Mail oder Passwort!'
}

export function getErrorReason(error) {
  const msg = 'Versuchen Sie bitte nochmal später.'
  if (error.response) {
    const { status } = error.response
    if (403 === status) {
      return 'Sie haben keine Berechtigung für die Aktion.'
    }
    if (500 <= status) {
      return `Server Fehler! ${msg}`
    }
    for (const prop of knownErrorProps) {
      const propValue = error.response.data[prop]
      if (propValue) {
        return knownErrorMessages[propValue] || propValue
      }
    }
  } else if (error.request) {
    return `Verbindungsfehler! ${msg}`
  }
  return 'Fehler!'
}

export function onUnauthorized(callback) {
  http.interceptors.response.use(undefined, (error) => {
    if (error.response?.status === 401) {
      callback()
    }
    throw error
  })
}
