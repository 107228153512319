import http from '../http'
import User from '@/entities/user'

async function login(credentials) {
  return new User((await http.post('/api/login', credentials)).data)
}

async function whoAmI() {
  return new User((await http.get('/api/me')).data)
}

async function logout() {
  await http.post('/api/logout')
}

async function verifyPassword(password) {
  await http.post('/api/verify', {password})
}

async function persistPasswordResetToken(payload) {
  return (await http.post('/api/password_reset_tokens', payload)).data
}

async function updatePasswordResetToken(id, patch) {
  return (await http.put(`/api/password_reset_tokens/${id}`, patch)).data
}

export default {
  login,
  whoAmI,
  logout,
  verifyPassword,
  persistPasswordResetToken,
  updatePasswordResetToken,
}
