import { store } from '@/store'

export const textFields = [
  {name: 'themeText', label: 'Thema'},
  {name: 'homeworksText', label: 'Hausaufgaben'},
  {name: 'eventsText', label: 'Ereignisse'},
  {name: 'commentText', label: 'Bemerkung'}
]


export default class ActualLesson {
  constructor(rawData) {
    Object.assign(this, rawData)
  }

  hasTexts() {
    return !!textFields.find(({ name }) => !!this[name])
  }

  hasTeacher(teacher) {
    return !!teacher && !!(
      this.scheduledLesson?.teachers?.includes(teacher) ||
      this.replacement?.teachers?.includes(teacher)
    )
  }

  getOriginalSchoolSubject() {
    if (this.scheduledLesson?.course) {
      const course = store.getters['common/courseByIri'](this.scheduledLesson.course)
      return store.getters['common/schoolSubjectByIri'](course?.schoolSubject)
    }
    return store.getters['common/schoolSubjectByIri'](this.scheduledLesson?.schoolSubject)
  }

  getSchoolSubject() {
    if (this.replacement?.schoolSubject) {
      return store.getters['common/schoolSubjectByIri'](this.replacement.schoolSubject)
    }
    return this.getOriginalSchoolSubject()
  }
}
