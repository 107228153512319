import apiClient from '@/api-client'


const state = {
  token: null,
  status: {}
}


const getters = {}


const mutations = {

  RESET_STATE(state) {
    state.token = null
    state.status = {}
  },

  CREATE_START(state) {
    state.token = null
    state.status = {isRequesting: true}
  },

  CREATE_SUCCESS(state, token) {
    state.token = token
    state.status = {}
  },

  UPDATE_START(state) {
    state.status = {isUpdating: true}
  },

  UPDATE_SUCCESS(state, { expiresAt }) {
    state.token = expiresAt ? {...state.token, expiresAt} : null
    state.status = {}
  },

  ERROR(state, error) {
    state.status = {
      error,
      reason: apiClient.getErrorReason(error)
    }
  }

}


const actions = {

  async verifyCurrentPassword({ commit }, password) {
    commit('CREATE_START')
    try {
      await apiClient.verifyPassword(password)
    } catch (error) {
      commit('ERROR', error)
      throw error
    }
  },

  async create({ commit }, payload) {
    commit('CREATE_START')
    try {
      const token = await apiClient.persistPasswordResetToken(payload)
      commit('CREATE_SUCCESS', token)
      return token
    } catch (error) {
      commit('ERROR', error)
      throw error
    }
  },

  async update({ commit }, patch) {
    commit('UPDATE_START')
    try {
      const updatedToken = await apiClient.updatePasswordResetToken(state.token.id, patch)
      commit('UPDATE_SUCCESS', updatedToken)
      return updatedToken
    } catch (error) {
      commit('ERROR', error)
      throw error
    }
  }
}


export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
