import http from '../http'

async function findByAcademicYear(academicYear) {
  const academicYearIri = academicYear?.['@id'] || academicYear
  if (!academicYearIri) return
  return (await http.get(`${academicYearIri}/school_classes`, {
    params: {
      pagination: false,
      'order[position]': 'asc',
      'order[code]': 'asc'
    }
  })).data['hydra:member']
}

export default {
  findByAcademicYear,
}
