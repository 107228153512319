import http from '../http'

async function findBy(criteria) {
  const params = {
    pagination: false,
    'order[modifiedAt]': 'asc',
    'order[createdAt]': 'asc',
    ...criteria,
  }
  return (await http.get('/api/week_comments', {params}))
    .data['hydra:member']
}

async function persist(payload) {
  return (await http.post('/api/week_comments', payload)).data
}

async function remove(id) {
  await http.delete(`/api/week_comments/${id}`)
}

export default {
  findBy,
  persist,
  remove,
}
