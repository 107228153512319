import http from '../http'
import Teacher from '@/entities/teacher'

async function findAll() {
  return (await http.get('/api/teachers', {
    params: {
      pagination: false,
      'order[lastName]': 'asc',
      'order[firstName]': 'asc',
    }
  })).data['hydra:member']
  .map((raw) => new Teacher(raw))
}

export default {
  findAll,
}
