import http from '../http'

async function findBySection(section) {
  const sectionIri = section?.['@id'] || section
  if (!sectionIri) return
  return (await http.get(`${sectionIri}/courses`, {
    params: {
      pagination: false,
      'order[position]': 'asc',
      'order[code]': 'asc'
    }
  })).data['hydra:member']
}

export default {
  findBySection,
}
