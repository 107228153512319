import http from '../http'
import Section from '@/entities/section'

async function findAll() {
  return (await http.get('/api/sections', {
    params: {
      pagination: false,
      'order[startsOn]': 'asc'
    }
  })).data['hydra:member']
    .map((raw) => new Section(raw))
}

export default {
  findAll,
}
