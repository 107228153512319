import http from '../http'

async function findBy(criteria = {}) {
  const params = {
    pagination: false,
    ...criteria,
  }
  return (await http.get('/api/student_absences', {params}))
    .data['hydra:member']
}

async function countBy(criteria = {}) {
  const params = {
    ...criteria,
    itemsPerPage: 0,
  }
  return (await http.get('/api/student_absences', {params})).data['hydra:totalItems']
}

async function updateByStudents(students, patch) {
  const payload = {
    students: students.map(s => s['@id'] || s),
    ...patch,
  }
  await http.put('/api/student_absences', payload)
}

async function removeByStudents(students, criteria) {
  const payload = {
    students: students.map(s => s['@id'] || s),
    ...criteria,
  }
  await http.put('/api/student_absences/remove', payload)
}

async function getSummaryByContextObject(contextObject, criteria = {})
{
  const iri = contextObject?.['@id']
  const type = contextObject?.['@type']
  if (!(iri && ['SchoolClass', 'Course'].includes(type))) return
  return (await http.get(`${iri}/student_absences_summary`, {params: criteria})).data
}

export default {
  findBy,
  countBy,
  updateByStudents,
  removeByStudents,
  getSummaryByContextObject,
}
