import http from '../http'
import User from '@/entities/user'

async function findAll() {
  const params = {
    pagination: false,
  }
  return (await http.get('/api/users', {params}))
    .data['hydra:member']
    .map((raw) => new User(raw))
}

async function persist(payload) {
  return new User((await http.post('/api/users', payload)).data)
}

async function update(id, patch) {
  return new User((await http.put(`/api/users/${id}`, patch)).data)
}

async function remove(id) {
  await http.delete(`/api/users/${id}`)
}

async function removeAvatar(id) {
  await http.delete(`/api/users/${id}/avatar`)
}

async function uploadAvatar(id, file) {
  if (!file?.type.startsWith('image/')) {
    return
  }
  const reader = new FileReader()
  reader.onload = async () => {
    await http({
      url: `/api/users/${id}/avatar`,
      method: 'put',
      data: reader.result,
      headers: {'Content-Type': file.type}
    })
  }
  reader.readAsArrayBuffer(file);
}

export default {
  findAll,
  persist,
  update,
  remove,
  removeAvatar,
  uploadAvatar,
}
