import apiClient from '@/api-client'


const state = {
  dialogDelete: false,
  dialogEdit: false,
  dialogEditRoles: false,
  dialogImport: false,
  dialogInvite: false,
  editedUser: null,
  loadingUsers: false,
  savingUser: false,
  users: [],
  selectedUsers: [],
  activeUser: null,
}


const mutations = {
  PATCH(state, patch) {
    Object.assign(state, patch)
  },
}


const actions = {
  async fetchUsers({ commit, dispatch }, { silent } = {silent: false}) {
    if (!silent) commit('PATCH', {loadingUsers: true})
    try {
      const users = await apiClient.users.findAll()
      commit('PATCH', {users})

    } catch (e) {
      console.error(e)
    } finally {
      commit('PATCH', {loadingUsers: false})
    }
    dispatch('refreshActiveUser')
    dispatch('refreshSelectedUsers')
  },
  setActiveUser({ commit }, activeUser) {
    commit('PATCH', {activeUser})
  },
  setSelectedUsers({ commit }, selectedUsers) {
    commit('PATCH', {selectedUsers})
  },
  refreshActiveUser({ commit, state }) {
    if (!state.activeUser) return
    const activeUser = state.users.find((u) => u['@id'] === state.activeUser['@id']) || null
    commit('PATCH', {activeUser})
  },
  refreshSelectedUsers({ commit, state }) {
    const selectedUsers = state.selectedUsers.map((selectedUser) =>
      state.users.find((u) => u['@id'] === selectedUser['@id'])
    ).filter((selectedUser) => !!selectedUser)
    commit('PATCH', {selectedUsers})
  },
  openDialog({ commit }, { dialog = 'edit', user = null } = {}) {
    commit('PATCH', {
      dialogDelete: 'delete' == dialog,
      dialogEdit: 'edit' == dialog,
      dialogEditRoles: 'edit-roles' == dialog,
      dialogImport: 'import' == dialog,
      dialogInvite: 'invite' == dialog,
      editedUser: user,
    })
  },
  closeDialog({ commit }) {
    commit('PATCH', {
      dialogDelete: false,
      dialogEdit: false,
      dialogEditRoles: false,
      dialogImport: false,
      dialogInvite: false,
      editedUser: null,
    })
  },
  async createUser({ commit }, payload) {
    commit('PATCH', {savingUser: true})
    try {
      await apiClient.users.persist(payload)
    } catch (e) {
      throw apiClient.getErrorReason(e)
    } finally {
      commit('PATCH', {savingUser: false})
    }
  },
  async patchUser({ commit }, { user, patch }) {
    commit('PATCH', {savingUser: true})
    try {
      await apiClient.users.update(user.id, patch)
    } catch (e) {
      throw apiClient.getErrorReason(e)
    } finally {
      commit('PATCH', {savingUser: false})
    }
  },
  async removeUser({ commit }, user) {
    commit('PATCH', {savingUser: true})
    try {
      await apiClient.users.remove(user.id)
    } catch (e) {
      throw apiClient.getErrorReason(e)
    } finally {
      commit('PATCH', {savingUser: false})
    }
  },
}


const getters = {
  teachersWithoutAccount(state, getters, rootState) {
    const teachersWithAccount = state.users.map((u) => u.teacher)
    const { teachers } = rootState.common
    return teachers.filter((t) => !teachersWithAccount.includes(t['@id']))
  },
  isCurrentUser(state, getters, rootState) {
    return (user) => user?.['@id'] === rootState.user?.['@id']
  },
}


export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
}
