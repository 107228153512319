import http from '../http'

async function findOneBy(criteria) {
  return (await http.get('/api/week_reports', {
    params: criteria,
  })).data?.['hydra:member']?.[0] || null
}

async function upsert(data) {
  if (data.id) {
    await http.put(`/api/week_reports/${data.id}`, {text: data.text})
  } else {
    await http.post('/api/week_reports', data)
  }
}

export default {
  findOneBy,
  upsert,
}
