import http from '../http'
import DayPlan from '@/entities/day-plan'

async function findBySection(section) {
  const sectionIri = section?.['@id'] || section
  if (!sectionIri) return
  return (await http.get(`${sectionIri}/day_plans`)).data
    .map((raw) => new DayPlan(raw))
}

export default {
  findBySection,
}
