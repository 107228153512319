import http from '../http'
import AcademicYear from '@/entities/academic-year'

async function findAll() {
  return (await http.get('/api/academic_years', {
    params: {
      pagination: false,
      'order[year]': 'asc'
    }
  })).data['hydra:member']
    .map((raw) => new AcademicYear(raw))
}

export default {
  findAll,
}
